import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import GatsbyImage from "gatsby-image"
import { FaHandPointer } from "react-icons/fa"
import { MdRefresh } from "react-icons/md"
import { BsArrowRightCircle } from "react-icons/bs"
import Layout from "../../components/layout"
import SEO from "../../components/SEO"
import BackgroundImage from "gatsby-background-image"
import { PageTitle } from "../../components/SiteComponents"
import {
  VirtualTourTitle,
  VRGoggles,
  ImageAndText,
} from "../../components/vr/VirtualTourComponents"

const MississaugaFNVirtualTour = ({ title }) => {
  const data = useStaticQuery(graphql`
    query MississaugaFNQuery {
      background: file(relativePath: { eq: "virtualTours.jps.jpg" }) {
        childImageSharp {
          fluid(quality: 50, maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      tourPhoto: file(
        relativePath: {
          eq: "virtualTours/mississaugaFirstNation/Mississauga First Nation Transfer Station Tour.png"
        }
      ) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      householdWaste: file(
        relativePath: {
          eq: "virtualTours/mississaugaFirstNation/HouseholdWaste.png"
        }
      ) {
        childImageSharp {
          fixed(quality: 50, width: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      back: file(
        relativePath: { eq: "virtualTours/mississaugaFirstNation/back.png" }
      ) {
        childImageSharp {
          fixed(quality: 100, width: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      chief: file(
        relativePath: {
          eq: "virtualTours/mississaugaFirstNation/Chief-Niganobe.png"
        }
      ) {
        childImageSharp {
          fixed(quality: 50, width: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      listen: file(
        relativePath: { eq: "virtualTours/mississaugaFirstNation/Listen.png" }
      ) {
        childImageSharp {
          fixed(quality: 50, width: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      goggles: file(
        relativePath: { eq: "virtualTours/mississaugaFirstNation/goggles.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const backgroundFluidImageStack = [
    data.background.childImageSharp.fluid,
    `linear-gradient(rgba(0, 117, 86, 0.65), rgba(0, 117, 86, 0.65))`,
  ].reverse()
  return (
    <Layout>
      <SEO title={title} />
      <BackgroundImage
        Tag="section"
        className=" flex-grow pb-20 flex flex-col"
        fluid={backgroundFluidImageStack}
      >
        <div className="text-white grid justify-items-center px-3 text-xl leading-tight max-w-6xl self-center  ">
          <VirtualTourTitle>{title}</VirtualTourTitle>
          <VRGoggles fluid={data.goggles.childImageSharp.fluid} />
          <p>
            Click on the link below to take a virtual tour of the MFN WTS. Best
            to use Internet Browsers: Chrome, Safari, Firefox, Edge, and some
            versions of Explorer.
          </p>
          <a
            href="https://mississaugafnvr.ca"
            title="Virtual tour of Misssissauga First Nation Waste Transfer Station"
            target="_blank"
            rel="noopener noreferrer"
            className=" relative w-full max-w-xl shadow-lg  rounded-md overflow-hidden"
          >
            <div className="absolute flex flex-col items-center  justify-center top-0 right-0 bottom-0 left-0 bg-gray-900 z-10 bg-opacity-50 hover:bg-opacity-75 text-3xl font-bold sm:tracking-wide hover:text-nofnecGreen ">
              <span className="flex">
                Take the tour now
                <BsArrowRightCircle className="ml-3 sm:ml-5 self-center" />
              </span>
              <div className="text-base font-normal italic">
                (opens in new window)
              </div>
            </div>
            <GatsbyImage
              fluid={data.tourPhoto.childImageSharp.fluid}
              className="relative"
            />
          </a>
          <section className="mt-10">
            <h2 className="text-center">Instructions</h2>
            <p>
              Please be patient, it may be slow to load and you may have to
              refresh/reload <MdRefresh className="inline" /> the page.
            </p>
            <p>
              Once loaded, you can use your mouse{" "}
              <FaHandPointer className="inline" /> (click and hold) to move
              around the facility.
            </p>

            <ImageAndText
              fixed={data.householdWaste.childImageSharp.fixed}
              text="For each area of the WTS there are banners you can click on to get a better look or hear more information."
            />
            <ImageAndText
              fixed={data.back.childImageSharp.fixed}
              text="To get back to the main view, click the ‘back’ banner; you may have to scroll around to find it though!"
              isLeft={false}
            />
            <ImageAndText
              fixed={data.chief.childImageSharp.fixed}
              text="We suggest starting your tour by watching the video of Chief Niganobe. The MFN WTS was opened in 2018 after the landfill closed in 2013. You can learn more from Peyton Pitawanakwat, MFN Environment Department, about the old landfill site by clicking on the ‘old green space banner’. "
            />
            <ImageAndText
              fixed={data.listen.childImageSharp.fixed}
              text="Next have a listen to Bea Rodh, Waste Coordinator for Mamaweswen Tribal Council, give a short introduction about the Transfer Station operations. In the background you will see the small operations building where the site attendant has an office, storage space for equipment, and adjacent covered storage sheds for batteries, household hazardous wastes, and an area for reusable/sharable items."
              isLeft={false}
            />
            <p>
              The blue dumpsters lined up are for both household garbage and
              recycling. Behind the dumpsters you can see vehicles drive up the
              ramp for offloading larger waste items, like mattresses, into
              bigger open bins.
            </p>
            <p>
              Over by the greenspace, there are separate areas for scrap wood /
              construction waste, electronics, appliances, scrap metal, and
              tires.
            </p>
          </section>
        </div>
      </BackgroundImage>
    </Layout>
  )
}

MississaugaFNVirtualTour.defaultProps = {
  title: "Virtual Tour of Mississauga First Nation Waste Transfer Site",
  element1: <div>Photo should go here</div>,
}

MississaugaFNVirtualTour.propTypes = {
  title: PropTypes.string.isRequired,
  element1: PropTypes.element.isRequired,
}

ImageAndText.defaultProps = {
  isLeft: true,
}

ImageAndText.propTypes = {
  fixed: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired,
  isLeft: PropTypes.bool,
}

export default MississaugaFNVirtualTour
